import React from "react";
import { Col, Card } from "react-bootstrap";
import { extractTime } from "./extractTime";

const LongCard = (props) => {
  const { index, job, bell, dates, openJob, home, chosen, language } = props;
  console.log(job.fromAdminType)
  return job.school !== "Admin" ? (
    <Col md={12} key={index} className="mb-4 mt-4">
      {home && extractTime(job)}
      <Card
        as="a"
        href={`/specific/${job.uniqueId}`}
        className={`align-middle long-card ${chosen}`}
      >
        {(chosen === "reg" || typeof chosen === "undefined") && bell}
        <Col
          className="my-auto"
          style={{
            marginLeft:
              chosen === "reg" || typeof chosen === "undefined" ? "" : "1.8rem",
          }}
        >
          <Card.Body className="no-padding my-auto">
            <Card.Title className="mb-0 mt-1 cBold t-lg ">
              {job.school}
            </Card.Title>
            <Card.Text
              className={`mb-0 cReg t-md ${
                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
              } `}
            >
              {dates} | {job.startTime + " - " + job.endTime}
            </Card.Text>
            <Card.Text
              className={`mb-0 cReg t-md ${
                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
              } `}
            >
              {language.fi
                ? job?.typeOfTemp?.name_fi
                : job?.typeOfTemp?.name_swe}
            </Card.Text>
          </Card.Body>
        </Col>
      </Card>
    </Col>
  ) : job.fromAdminType === "Update" ? (
    <Col md={12} key={index} className="mb-4 mt-4">
      {home && extractTime(job)}
      <Card
        as="a"
        href={`/specific/${job.uniqueId}`}
        className={`align-middle long-card ${chosen}`}
      >
        {(chosen === "reg" || typeof chosen === "undefined") && bell}
        <Col
          className="my-auto"
          style={{
            marginLeft:
              chosen === "reg" || typeof chosen === "undefined" ? "" : "1.8rem",
          }}
        >
          <Card.Body className="no-padding my-auto">
            <Card.Title className="mb-0 mt-1 cBold t-lg ">Vikaaria</Card.Title>
            <Card.Text
              className={`mb-0 cReg t-md ${
                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
              } `}
            >
              {dates}
            </Card.Text>
            <Card.Text
              className={`mb-0 cReg t-md ${
                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
              } `}
            >
              UPDATE
            </Card.Text>
          </Card.Body>
        </Col>
      </Card>
    </Col>
  ) : (
    <Col md={12} key={index} className="mb-4 mt-4">
      {home && extractTime(job)}
      <Card
        as="a"
        href={`/specific/${job.uniqueId}`}
        className={`align-middle height-override long-card ${chosen}`}
      >
        {(chosen === "reg" || typeof chosen === "undefined") && bell}
        <Col
          className="my-auto"
          style={{
            marginLeft:
              chosen === "reg" || typeof chosen === "undefined" ? "" : "1.8rem",
          }}
        >
          <Card.Body className="no-padding my-auto">
            <Card.Title className="mb-0 mt-1 cBold t-lg ">Vikaaria</Card.Title>
            <Card.Text
              className={`mb-0 cReg t-md ${
                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
              } `}
            >
              {dates}
            </Card.Text>
            <Card.Text
              className={`mb-0 cReg t-md ${
                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
              } `}
            >
              Vikaaria tiedottaa/informerar
            </Card.Text>
          </Card.Body>
        </Col>
      </Card>
    </Col>
  );
};
export default LongCard;
