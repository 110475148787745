import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Image,
  Card,
} from "react-bootstrap";
import "../../styles/body-verticalCenter.css";
import "./registration.css";
import MunicipalityList from "../../typeaheadData/muni";
import QualificationList from "../../typeaheadData/qualifications";
import StudentQualificationList from "../../typeaheadData/studentQualifications";
import TeacherQualificationList from "../../typeaheadData/qualificationsTeachers";
import { Typeahead } from "react-bootstrap-typeahead";
import logo from "../../images/logo.png";
import MobileLayout from "../mobile/mobileLayout";
import Cookies from "js-cookie";
import { withAlert } from "react-alert";
import Backbar from "../mobile/backbar";

class UserRegister extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",
      password: "",
      password2: "",
      user: true,
      regions: [],
      types: [],
      status: "",
      other: false,
      otherTxt: "",
      errors: {},
      lang: "",
      mobile: false,
      terms: false,
      notChecked: false,
      checked: false,
      language: Cookies.getJSON("_lang") ? Cookies.getJSON("_lang") : "fi",
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    const handler = () => {
      let match = window.matchMedia("(max-width: 600px)").matches;
      if (match) {
        this.setState({ mobile: true });
      } else {
        this.setState({ mobile: false });
      }
    };
    handler();
    window.addEventListener("resize", handler);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  handleLanguage = (e) => {
    this.setState({ language: e.currentTarget.id }, () => {
      Cookies.set("_lang", this.state.language);
    });
  };

  onChange = (e) => {
    if (
      (e.target.id === "status" && e.target.value === "Annan") ||
      e.target.value === "Muu"
    ) {
      this.setState({ other: true });
    } else if (e.target.id === "status" && e.target.value !== "Annan") {
      this.setState({ other: false });
    }
    this.setState({ [e.target.id]: e.target.value });
  };

  handleCheckbox = (e) => {
    let check = e.target.checked;
    if (check) {
      this.setState({ checked: true, notChecked: false });
    } else {
      this.setState({ checked: false });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  chooseText = (language) => {
    if (language === "fi") {
      if (this.state.status === "Opiskelija") {
        return "Opiskelen";
      }
      return "Kelpoisuudet";
    } else {
      if (this.state.status === "Studerande") {
        return "Jag studerar till";
      }
      return "Behörighet";
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const alert = this.props.alert;

    const newUser = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      confirmEmail: this.state.confirmEmail,
      phone: this.state.phone,
      language: this.state.lang,
      status: this.state.status,
      regions: this.state.regions,
      otherTxt: this.state.otherTxt,
      password: this.state.password,
      password2: this.state.password2,
      qualis: this.state.types,
    };
    if (this.state.checked) {
      this.props.registerUser(newUser, this.props.history);
    } else {
      this.setState({ notChecked: true });
    }
  };

  render() {
    const { errors, other, user, mobile, language } = this.state;
    if (user) {
      return (
        <div className={mobile ? "show-body" : "body"}>
          <Container
            fluid
            style={{
              paddingRight: mobile ? "0" : "",
              paddingLeft: mobile ? "0" : "",
              marginTop: mobile ? "0" : "1rem",
              marginBottom: "2rem",
            }}
          >
            {!mobile && (
              <Row className="justify-content-md-center text-center">
                <Col md={4}>
                  <Image fluid src={logo} alt="Logo" className="" />
                </Col>
              </Row>
            )}
            {!mobile ? (
              <Form noValidate onSubmit={this.onSubmit}>
                <Row className="justify-content-md-center mt-4">
                  <Col md={4}>
                    <Form.Group controlId="firstName">
                      <Form.Control
                        type="text"
                        placeholder={language === "fi" ? "Etunimi" : "Förnamn"}
                        onChange={this.onChange}
                        value={this.state.firstName}
                        className={classnames("", {
                          invalid: errors.firstName,
                        })}
                      />
                      <Form.Text className="text-danger st">
                        {errors.firstName}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    <Form.Group controlId="lastName">
                      <Form.Control
                        type="text"
                        placeholder={
                          language === "fi" ? "Sukunimi" : "Efternamn"
                        }
                        onChange={this.onChange}
                        value={this.state.lastName}
                        className={classnames("", {
                          invalid: errors.lastName,
                        })}
                      />
                      <Form.Text className="text-danger st">
                        {errors.lastName}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    <Form.Group controlId="phone">
                      <Form.Control
                        type="tel"
                        placeholder={language === "fi" ? "Puhelin" : "Telefon"}
                        onChange={this.onChange}
                        value={this.state.phone}
                        className={classnames("", {
                          invalid: errors.phone,
                        })}
                      />
                      <Form.Text className="text-danger st">
                        {errors.phone}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    <Form.Group controlId="email">
                      <Form.Control
                        autoCapitalize="off"
                        type="email"
                        placeholder={
                          language === "fi" ? "Sähköposti" : "E-post"
                        }
                        onChange={this.onChange}
                        value={this.state.email}
                        className={classnames("", {
                          invalid: errors.email,
                        })}
                      />
                      <Form.Text className="text-danger st">
                        {errors.email}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    <Form.Group controlId="confirmEmail">
                      <Form.Control
                        autoCapitalize="off"
                        type="email"
                        placeholder={
                          language === "fi"
                            ? "Vahvista sähköposti"
                            : "Bekräfta e-post"
                        }
                        onChange={this.onChange}
                        value={this.state.confirmEmail}
                        className={classnames("", {
                          invalid: errors.confirmEmail,
                        })}
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                      <Form.Text className="text-danger st">
                        {errors.confirmEmail}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    <Form.Group controlId="lang">
                      <Form.Control
                        as="select"
                        placeholder={
                          language === "fi" ? "Äidinkieli" : "Modersmål"
                        }
                        defaultValue="1"
                        onChange={this.onChange}
                        value={this.state.lang}
                        className={classnames("", {
                          invalid: errors.language,
                        })}
                      >
                        <option key="blankChoice" value hidden>
                          {language === "fi"
                            ? "Valitse äidinkieli"
                            : "Välj modersmål"}
                        </option>
                        <option value="Svenska">Svenska</option>
                        <option value="Finska">Suomi</option>
                      </Form.Control>
                      <Form.Text className="text-danger st">
                        {errors.language}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    <Form.Group controlId="status">
                      <Form.Control
                        as="select"
                        placeholder="status"
                        defaultValue="1"
                        onChange={this.onChange}
                        value={this.state.status}
                        className={classnames("", {
                          invalid: errors.status,
                        })}
                      >
                        <option key="blankChoice" value hidden>
                          {language === "fi" ? "Minä olen" : "Jag är"}
                        </option>
                        <option>
                          {language === "fi" ? "Opettaja" : "Lärare"}
                        </option>
                        <option>
                          {language === "fi" ? "Opiskelija" : "Studerande"}
                        </option>
                        <option>{language === "fi" ? "Muu" : "Annan"}</option>
                      </Form.Control>
                      <Form.Text className="text-danger st">
                        {errors.status}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                {other ? (
                  <Row className="justify-content-md-center mt-3">
                    <Col md="4">
                      <Form.Group controlId="otherTxt">
                        <Form.Control
                          type="text"
                          placeholder={
                            language === "fi" ? "Muu, mikä?" : "Annan, vad?"
                          }
                          onChange={this.onChange}
                          value={this.state.otherTxt}
                          className={classnames("", {
                            invalid: errors.otherTxt,
                          })}
                        />
                        <Form.Text className="text-danger st">
                          {errors.otherTxt}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    {/* language === "fi" ? "Kelpoisuudet" : "Behörighet" */}
                    <Form.Group>
                      <Typeahead
                        id="types-search"
                        labelKey={language === "fi" ? "name_fi" : "name_swe"}
                        multiple
                        onInputChange={() => true}
                        filterBy={() => true}
                        options={
                          this.state.status === "Opiskelija"
                            ? StudentQualificationList
                            : TeacherQualificationList
                        }
                        placeholder={this.chooseText(language)}
                        defaultSelected={this.state.types}
                        onChange={(types) => {
                          this.setState({ types });
                        }}
                      />
                      <Form.Text className="text-danger st">
                        {errors.types}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    <Form.Group>
                      <Typeahead
                        id="municipality-search"
                        labelKey={language === "fi" ? "namefi" : "name_swe"}
                        multiple
                        options={MunicipalityList}
                        placeholder={
                          language === "fi"
                            ? "Sijaistan alueilla"
                            : "Jag vill vikariera i"
                        }
                        defaultSelected={this.state.regions}
                        onChange={(regions) => {
                          this.setState({ regions });
                        }}
                      />
                      <Form.Text className="text-danger st">
                        {errors.regions}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    <Form.Group controlId="password">
                      <Form.Control
                        type="password"
                        id="password"
                        placeholder={
                          language === "fi" ? "Salasana" : "Lösenord"
                        }
                        onChange={this.onChange}
                        value={this.state.password}
                        className={classnames("", {
                          invalid: errors.password,
                        })}
                      />
                      <Form.Text className="text-danger st">
                        {errors.password}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    <Form.Group controlId="password2">
                      <Form.Control
                        type="password"
                        id="password2"
                        placeholder={
                          language === "fi"
                            ? "Vahvista salasana"
                            : "Bekräfta lösenord"
                        }
                        onChange={this.onChange}
                        value={this.state.password2}
                        className={classnames("", {
                          invalid: errors.password2,
                        })}
                      />
                      <Form.Text className="text-danger st">
                        {errors.password2}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-3">
                  <Col md="4">
                    <Form.Group controlId="terms">
                      <Form.Check
                        className="d-flex align-items-center"
                        onChange={this.handleCheckbox}
                        type="checkbox"
                        label={
                          language === "fi" ? (
                            <>
                              Olen lukenut ja hyväksyn{" "}
                              <a target="_blank" href="/terms&conditions">
                                ehdot
                              </a>
                            </>
                          ) : (
                            <>
                              Jag har läst och godkänner{" "}
                              <a target="_blank" href="/terms&conditions">
                                användarvillkoren
                              </a>
                            </>
                          )
                        }
                      />
                      {this.state.notChecked && (
                        <small className="t-md text-danger">
                          {language === "fi"
                            ? "Ole hyvä, hyväksy ehdot"
                            : "Var god, godkänn användarvillkoren"}
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center ">
                  <Col md="4">
                    <Button variant="custom" block size="lg" type="submit">
                      {language === "fi" ? "Luo tili" : "Skapa konto"}
                    </Button>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mt-2">
                  <Col md="4">
                    <Button
                      variant="customDelete-reverse"
                      onClick={() => this.props.history.push("/login")}
                      block
                      size="lg"
                    >
                      {language === "fi" ? "peruuta" : "avbryt"}
                    </Button>
                    {/* PERUUTA */}
                  </Col>
                </Row>
              </Form>
            ) : (
              <>
                {mobile && (
                  <MobileLayout
                    text={language === "fi" ? "Luo tili" : "Skapa konto"}
                    renderCard={false}
                    lessPadding={true}
                  />
                )}
                <Card
                  style={{
                    marginRight: "2em",
                    marginLeft: "2em",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                    marginBottom: "2em",
                  }}
                >
                  <Backbar
                    padding={true}
                    sticky={mobile}
                    desktop={!mobile}
                    goback={this.goBack}
                    title={language === "fi" ? "Takaisin" : "Tillbaka"}
                  />
                  <Form noValidate onSubmit={this.onSubmit}>
                    <Row>
                      <Col>
                        <Card.Body className="">
                          <Card.Title className="text-center text-muted">
                            {language === "fi"
                              ? "Täytä tietosi alla päästäksesi alkuun"
                              : "Fyll i dina upgifter nedan för att komma igång"}
                          </Card.Title>
                          <Row className="justify-content-md-center mt-4">
                            <Col md={4}>
                              <Form.Group controlId="firstName">
                                <Form.Control
                                  type="text"
                                  placeholder={
                                    language === "fi" ? "Etunimi" : "Förnamn"
                                  }
                                  onChange={this.onChange}
                                  value={this.state.firstName}
                                  className={classnames("", {
                                    invalid: errors.firstName,
                                  })}
                                />
                                <Form.Text className="text-danger st">
                                  {errors.firstName}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group controlId="lastName">
                                <Form.Control
                                  type="text"
                                  placeholder={
                                    language === "fi" ? "Sukunimi" : "Efternamn"
                                  }
                                  onChange={this.onChange}
                                  value={this.state.lastName}
                                  className={classnames("", {
                                    invalid: errors.lastName,
                                  })}
                                />
                                <Form.Text className="text-danger st">
                                  {errors.lastName}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group controlId="phone">
                                <Form.Control
                                  type="tel"
                                  placeholder={
                                    language === "fi" ? "Puhelin" : "Telefon"
                                  }
                                  onChange={this.onChange}
                                  value={this.state.phone}
                                  className={classnames("", {
                                    invalid: errors.phone,
                                  })}
                                />
                                <Form.Text className="text-danger st">
                                  {errors.phone}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group controlId="email">
                                <Form.Control
                                  autoCapitalize="off"
                                  type="email"
                                  placeholder={
                                    language === "fi" ? "Sähköposti" : "E-post"
                                  }
                                  onChange={this.onChange}
                                  value={this.state.email}
                                  className={classnames("", {
                                    invalid: errors.email,
                                  })}
                                />
                                <Form.Text className="text-danger st">
                                  {errors.email}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group controlId="confirmEmail">
                                <Form.Control
                                  autoCapitalize="off"
                                  type="email"
                                  placeholder={
                                    language === "fi"
                                      ? "Vahvista sähköposti"
                                      : "Bekräfta e-post"
                                  }
                                  onChange={this.onChange}
                                  value={this.state.confirmEmail}
                                  className={classnames("", {
                                    invalid: errors.confirmEmail,
                                  })}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                />
                                <Form.Text className="text-danger st">
                                  {errors.confirmEmail}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group controlId="lang">
                                <Form.Control
                                  as="select"
                                  placeholder={
                                    language === "fi"
                                      ? "Äidinkieli"
                                      : "Modersmål"
                                  }
                                  defaultValue="1"
                                  onChange={this.onChange}
                                  value={this.state.lang}
                                  className={classnames("", {
                                    invalid: errors.language,
                                  })}
                                >
                                  <option key="blankChoice" value hidden>
                                    {language === "fi"
                                      ? "Valitse äidinkieli"
                                      : "Välj modersmål"}
                                  </option>
                                  <option value="Svenska">Svenska</option>
                                  <option value="Finska">Suomi</option>
                                </Form.Control>
                                <Form.Text className="text-danger st">
                                  {errors.language}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group controlId="status">
                                <Form.Control
                                  as="select"
                                  placeholder="status"
                                  defaultValue="1"
                                  onChange={this.onChange}
                                  value={this.state.status}
                                  className={classnames("", {
                                    invalid: errors.status,
                                  })}
                                >
                                  <option key="blankChoice" value hidden>
                                    {language === "fi" ? "Minä olen" : "Jag är"}
                                  </option>
                                  <option>
                                    {language === "fi" ? "Opettaja" : "Lärare"}
                                  </option>
                                  <option>
                                    {language === "fi"
                                      ? "Opiskelija"
                                      : "Studerande"}
                                  </option>
                                  <option>
                                    {language === "fi" ? "Muu" : "Annan"}
                                  </option>
                                </Form.Control>
                                <Form.Text className="text-danger st">
                                  {errors.status}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          {other ? (
                            <Row className="justify-content-md-center mt-3">
                              <Col md="4">
                                <Form.Group controlId="otherTxt">
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      language === "fi"
                                        ? "Muu, mikä?"
                                        : "Annan, vad?"
                                    }
                                    onChange={this.onChange}
                                    value={this.state.otherTxt}
                                    className={classnames("", {
                                      invalid: errors.otherTxt,
                                    })}
                                  />
                                  <Form.Text className="text-danger st">
                                    {errors.otherTxt}
                                  </Form.Text>
                                </Form.Group>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group>
                                <Typeahead
                                  id="types-search"
                                  labelKey={
                                    language === "fi" ? "name_fi" : "name_swe"
                                  }
                                  multiple
                                  options={
                                    this.state.status === "Opiskelija"
                                      ? StudentQualificationList
                                      : QualificationList
                                  }
                                  placeholder={this.chooseText(language)}
                                  defaultSelected={this.state.types}
                                  onChange={(types) => {
                                    this.setState({ types });
                                  }}
                                />
                                <Form.Text className="text-danger st">
                                  {errors.types}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group>
                                <Typeahead
                                  id="municipality-search"
                                  labelKey={
                                    language === "fi" ? "namefi" : "name_swe"
                                  }
                                  multiple
                                  options={MunicipalityList}
                                  placeholder={
                                    language === "fi"
                                      ? "Sijaistan alueilla"
                                      : "Jag vill vikariera i"
                                  }
                                  defaultSelected={this.state.regions}
                                  onChange={(regions) => {
                                    this.setState({ regions });
                                  }}
                                />
                                <Form.Text className="text-danger st">
                                  {errors.regions}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group controlId="password">
                                <Form.Control
                                  type="password"
                                  id="password"
                                  placeholder={
                                    language === "fi" ? "Salasana" : "Lösenord"
                                  }
                                  onChange={this.onChange}
                                  value={this.state.password}
                                  className={classnames("", {
                                    invalid: errors.password,
                                  })}
                                />
                                <Form.Text className="text-danger st">
                                  {errors.password}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group controlId="password2">
                                <Form.Control
                                  type="password"
                                  id="password2"
                                  placeholder={
                                    language === "fi"
                                      ? "Vahvista salasana"
                                      : "Bekräfta lösenord"
                                  }
                                  onChange={this.onChange}
                                  value={this.state.password2}
                                  className={classnames("", {
                                    invalid: errors.password2,
                                  })}
                                />
                                <Form.Text className="text-danger st">
                                  {errors.password2}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Form.Group className="" controlId="terms">
                                <Form.Check
                                  onChange={this.handleCheckbox}
                                  className="d-flex align-items-center"
                                  type="checkbox"
                                  label={
                                    language === "fi" ? (
                                      <>
                                        Olen lukenut ja hyväksyn{" "}
                                        <a
                                          target="_blank"
                                          href="/terms&conditions"
                                        >
                                          ehdot
                                        </a>
                                      </>
                                    ) : (
                                      <>
                                        Jag har läst och godkänner{" "}
                                        <a
                                          target="_blank"
                                          href="/terms&conditions"
                                        >
                                          användarvillkoren
                                        </a>
                                      </>
                                    )
                                  }
                                />
                                {this.state.notChecked && (
                                  <small className="t-md text-danger">
                                    {language === "fi"
                                      ? "Ole hyvä, hyväksy ehdot"
                                      : "Var god, godkänn användarvillkoren"}
                                  </small>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center mt-3">
                            <Col md="4">
                              <Button
                                variant="custom"
                                className="mb-3"
                                block
                                size="lg"
                                type="submit"
                              >
                                {language === "fi" ? "Luo tili" : "Skapa konto"}
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </>
            )}
          </Container>
        </div>
      );
    }
  }
}

UserRegister.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(
  withRouter(withAlert()(UserRegister))
);
