import React, { useState, useEffect, useRef } from "react";
import { Image, Col, Card } from "react-bootstrap";
import "./mobilestyles.css";
import mobileLogo2 from "../../images/mobile_logo2.png";
import MobileCard from "../cards/mobileCard";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { default as Inactivate } from "../cards/swipe-icons/Delete_Sweep.svg";
import { default as Delete } from "../cards/swipe-icons/Delete_Forever.svg";
import { default as Reactivate } from "../cards/swipe-icons/Aterstall.svg";
import { default as MoreDots } from "../tempjob/icons/dot_menu.svg";
import DefaultAvatar from "../../images/avatar_default.png";
import { ReactHeight } from "react-height";
import RemoveJobModal from "./removeJobModal";
import MoreSwipeModal from "./moreSwipeModal";
import DeleteSwipeModal from "./deleteSwipeModal";

const MobileHeader = (props) => {
  const {
    icon,
    text,
    jobs,
    mobileBell,
    displayDates,
    openJob,
    renderCard,
    chosen,
    swipeable,
    inactivatejob,
    user,
    inactivejobs,
    blockSchool,
    activatejob,
    removeJob,
    avatar,
    removeApplication,
    language,
    noJobs,
    resMsg,
    lessPadding,
  } = props;

  const [heightOfSection, setHeightOfSection] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [removeData, setRemoveData] = useState({});
  const [moreOpen, setMoreOpen] = useState(false);
  const [delOpen, setDelOpen] = useState(false);

  const imgUrl = `${window.location.origin}/api/shared/image/`;

  const isEmptyObject = (obj) => {
    if (typeof obj === "undefined" || typeof obj === "null") return true;
    return Object.keys(obj).length === 0;
  };

  const handleCloseChange = () => {
    setIsOpen(false);
  };

  const handleMoreCloseChange = () => {
    setMoreOpen(false);
  };

  const handleDeleteCloseChange = () => {
    setDelOpen(false);
  };

  const handleIconClassName = (text) => {
    switch (text) {
      case "Vikariat":
      case "Sijaisuudet":
        return "mobile-header-svg-other mr-2";
        break;
      case "Mina anmälningar":
      case "Ilmoitustaulu":
        return "mobile-header-svg-applied mr-2";
        break;
      case "Mer":
      case "Lisää":
        return "mobile-header-svg-more mr-4";
        break;
      default:
        return "mobile-header-svg mr-2";
    }
  };

  /* useEffect(() => {
        calcHeightOfInactiveSection()
    }, []) */

  const UserAvatar = imgUrl + user?.image;

  const handleInactivate = (id) => {
    let data = {
      id: id,
      email: user.email,
    };
    inactivatejob(data);
  };

  const handleBlockSchool = (data) => {
    blockSchool(data);
  };

  const handleMore = (id) => {
    let data = {
      id,
      email: user.email,
    };
    setRemoveData(data);
    setMoreOpen(true);
  };

  const handleActivate = (id) => {
    let data = {
      id: id,
      email: user.email,
    };
    activatejob(data);
  };

  const handleWantToRemove = (id) => {
    let data = {
      id,
      email: user.email,
    };
    setRemoveData(data);
    setDelOpen(true);
  };

  const handleRemove = (id) => {
    let data = {
      id: id,
      email: user.email,
    };
    removeJob(data);
  };

  const handleApplicantRemove = (id) => {
    let data = {
      id: id,
      email: user.email,
    };
    setRemoveData(data);
    setIsOpen(true);
    /* if (window.confirm("Är du säker att du vill ta bort din ansökning")) {
            removeJob(data)
        } */
  };

  const leadingActions = (id) => (
    <LeadingActions>
      <SwipeAction onClick={() => handleActivate(id)}>
        <div className="swipe-restore-container">
          <img src={Reactivate} className="swipe-icon" />
          <p className="swipe-text">{language.reset}</p>
        </div>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (id) => (
    <LeadingActions>
      <SwipeAction
        /* destructive={true} */
        onClick={() => handleMore(id)}
      >
        <div className="swipe-inactive-container">
          <img src={MoreDots} className="swipe-icon-dots" />
          <p className="swipe-text">{language.more}</p>
        </div>
      </SwipeAction>
    </LeadingActions>
  );

  const vikariatDeleteJob = (id) => (
    <TrailingActions>
      <SwipeAction onClick={() => handleWantToRemove(id)}>
        <div className="swipe-delete-container">
          <img alt="" src={Delete} className="swipe-trash-icon" />
          <p className="swipe-text">{language.throw}</p>
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  const removeApplied = (id) => (
    <TrailingActions>
      <SwipeAction onClick={() => handleApplicantRemove(id)}>
        <div className="swipe-delete-container">
          <img alt="" src={Delete} className="swipe-trash-icon" />
          <p className="swipe-text">{language.removeapl}</p>
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <>
      <div className="mobile-h">
        {language && (
          <RemoveJobModal
            removejob={removeJob}
            language={language}
            data={removeData}
            isOpen={isOpen}
            handleCloseChange={handleCloseChange}
          />
        )}
        {moreOpen && (
          <MoreSwipeModal
            handleBlockSchool={handleBlockSchool}
            handleInactivate={handleInactivate}
            language={language}
            data={removeData}
            isOpen={moreOpen}
            handleCloseChange={handleMoreCloseChange}
          />
        )}
        {delOpen && (
          <DeleteSwipeModal
            language={language}
            isOpen={delOpen}
            handleCloseChange={handleDeleteCloseChange}
            handleDeleteJob={handleRemove}
            data={removeData}
          />
        )}
        <div
          className="m-header" /* {scrollY >= 105 ? "m-header-scroll" : "m-header"} */
        >
          {/* <Image fluid src={mobileHeader} alt="header" className="mobile-header-image" /> */}
          <Image
            fluid
            src={mobileLogo2}
            alt="logo"
            className="mobile-l" /* {scrollY >= 105 ? "mobile-l" : "mobile-l logo-anim"} */
          />
          {avatar && (
            <Image
              fluid
              alt="avatar"
              className="avatar"
              src={!isEmptyObject(user.image) ? UserAvatar : DefaultAvatar}
            />
          )}
          <p className="header-text">
            {icon && (
              <Image alt="" src={icon} className={handleIconClassName(text)} />
            )}
            {text}
          </p>
        </div>
        <div
          className={
            lessPadding ? "m-cards-lessPad" : "m-cards"
          } /* {scrollY >= 105 ? "m-cards-scroll" : "m-cards"} */
        >
          {noJobs && (
            <Col className="">
              <Card
                style={{
                  border: "none",
                  backgroundColor: "#ffa53a",
                  color: "white",
                  fontSize: "1.6em",
                  fontWeight: "600",
                  textAlign: "center",
                  borderRadius: "14px",
                  boxShadow: "0px 0px 24px 0px rgb(13 17 77 / 10%)",
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
              >
                <Col>
                  <Card.Body>
                    <Card.Text>{resMsg}</Card.Text>
                  </Card.Body>
                </Col>
              </Card>
            </Col>
          )}
          {swipeable &&
            (text === "Mina anmälningar" || text === "Ilmoittautumiset") && (
              <SwipeableList style={{ paddingTop: "1rem" }}>
                {jobs.map((job, index) =>
                  job.chosen !== user.email &&
                  typeof job.chosen !== "undefined" ? (
                    <SwipeableListItem
                      key={job.uniqueId}
                      /* leadingActions={leadingActions(job.uniqueId)} */
                      trailingActions={vikariatDeleteJob(job.uniqueId)}
                    >
                      <MobileCard
                        bell={mobileBell(job)}
                        job={job}
                        index={index}
                        openJob={openJob}
                        chosen={chosen ? chosen(job) : ""}
                        dates={displayDates(job)}
                        margin={"mobile-margin"}
                        c_name="m-card"
                        id={job.uniqueId}
                        language={language}
                      />
                    </SwipeableListItem>
                  ) : job.chosen === user.email &&
                    typeof job.chosen != "undefined" ? (
                    <MobileCard
                      bell={mobileBell(job)}
                      job={job}
                      index={index}
                      openJob={openJob}
                      chosen={chosen ? chosen(job) : ""}
                      dates={displayDates(job)}
                      margin={"mobile-margin"}
                      c_name="m-card"
                      id={job.uniqueId}
                      language={language}
                    />
                  ) : (
                    <SwipeableListItem
                      trailingActions={removeApplied(job.uniqueId)}
                      key={job.uniqueId}
                    >
                      <MobileCard
                        bell={mobileBell(job)}
                        job={job}
                        index={index}
                        openJob={openJob}
                        chosen={chosen ? chosen(job) : ""}
                        dates={displayDates(job)}
                        margin={"mobile-margin"}
                        c_name="m-card"
                        id={job.uniqueId}
                        language={language}
                      />
                    </SwipeableListItem>
                  )
                )}
              </SwipeableList>
            )}
          {swipeable && (text === "Vikariat" || text === "Sijaisuudet") && (
            <>
              <SwipeableList
                style={{ paddingTop: jobs.length > 0 ? "1rem" : "" }}
              >
                {jobs.map((job, index) => (
                  <SwipeableListItem
                    key={job.uniqueId}
                    leadingActions={trailingActions(job.uniqueId)}
                    trailingActions={vikariatDeleteJob(job.uniqueId)}
                  >
                    <MobileCard
                      bell={mobileBell(job)}
                      job={job}
                      index={index}
                      openJob={openJob}
                      chosen={chosen ? chosen(job) : ""}
                      dates={displayDates(job)}
                      margin={"mobile-margin"}
                      c_name="m-card"
                      id={job.uniqueId}
                      grayText={true}
                      language={language}
                    />
                  </SwipeableListItem>
                ))}
              </SwipeableList>
              <div
                style={{ height: heightOfSection }}
                className={`inactive-section ${
                  inactivejobs.length < 1 && "hide-section"
                }`}
              >
                <ReactHeight
                  onHeightReady={(height) => setHeightOfSection(height)}
                >
                  <p className="inactive-title">{language.inactivejobs}</p>
                  <SwipeableList>
                    {inactivejobs.map((job, index) => (
                      <SwipeableListItem
                        key={job.uniqueId}
                        leadingActions={leadingActions(job.uniqueId)}
                        trailingActions={vikariatDeleteJob(job.uniqueId)}
                      >
                        <MobileCard
                          bell={mobileBell(job)}
                          job={job}
                          index={index}
                          openJob={openJob}
                          chosen={chosen ? chosen(job) : ""}
                          dates={displayDates(job)}
                          margin={"mobile-margin"}
                          c_name="m-card"
                          inactive={true}
                          id={job.uniqueId}
                          grayText={true}
                          language={language}
                        />
                      </SwipeableListItem>
                    ))}
                  </SwipeableList>
                </ReactHeight>
              </div>
            </>
          )}
          {renderCard &&
            !swipeable &&
            jobs.map((job, index) => (
              <MobileCard
                bell={mobileBell(job)}
                job={job}
                index={index}
                openJob={openJob}
                chosen={chosen ? chosen(job) : ""}
                dates={displayDates(job)}
                margin={"mobile-margin"}
                c_name="m-card"
                language={language}
              />
            ))}
        </div>
      </div>
    </>
  );
};
export default MobileHeader;
