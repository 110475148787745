import React from 'react'

const TermsFI = () => {
    return (
        <div className="d-inline-block text-left">

            <h2 className="termsHeader">1. Sopijapuolet</h2>
            <p className="termsParagraf">Näitä ehtoja sovelletaan Listit Software Oy:n sekä maksavan asiakasosapuolen kanssa</p>

            <h2 className="termsHeader">2. Sopijapuolten oikeudet ja velvollisuudet</h2>
            <h2 className="termsSecondHeader">2.1. Oppilaitoksen ja Sijaisen vastuu</h2>

            <p className="termsParagraf">
                Oppilaitoksella tarkoitetaan koulussa työskentelevää rehtoria sekä niitä opettajia joille on annettu Vikaaria palvelun käyttöoikeus.
            </p>
            <p className="termsParagraf">
                Oppilaitos vastaa Vikaaria palveluun antamistaan tiedoista ja on viipymättä velvollinen päivittämään niissä tapahtuneet muutokset.
            </p>
            <p className="termsParagraf">
                OSijaisen on ilmoitettava Vikaaria palvelussa riittävät yhteystiedot  (mm. nimi, puhelinnumero, sähköpostiosoite, äidinkieli, toimiala (jos on esim. opiskelija, opettaja tai muu) sekä miltä alueelta haluaa vastaanottaa sijaisuuksia.
            </p>
            <p className="termsParagraf">
                Sijaisella ei ole oikeutta siirtää Vikaaria palvelun käyttöoikeutta kolmannelle osapuolelle tai löytää itselleen jo hyväksytyn sijaisuuden sijaan sijaisen ilman oppilaitoksen erillistä hyväksyntää. Oppilaitos on vastuussa käyttäjätunnuksellaan ja salasanallaan tapahtuneesta Vikaaria palvelun käytöstä.                 </p>
            <p className="termsParagraf">
                Oppilaitoksen ja sijaisen antamien tietojen aitoudesta vastaa oppilaitos ja sijainen itse.
            </p>
            <p className="termsParagraf">
                Sijainen sitoutuu toimimaan oppilaitoksen sääntöjä sekä hyviä käytöstapoja noudattaen.
            </p>

            <h2 className="termsSecondHeader">2.2. Listit Software Oy:n vastuu </h2>
            <p className="termsParagraf">
                Listit Software Oy: luovuttaa oppilaitokselle oikeuden käyttää Vikaaria palvelua näiden ehtojen mukaisesti.
            </p>
            <p className="termsParagraf">
                Koska Vikaaria palvelu on sijaisuuksia koskevan kysyntä- ja tarjontatiedon markkinapaikka, ei Listit Software Oy: millään lailla vastaa sijaisista tai heidän toiminnastaan. Sijaisen ja oppilaitoksen suhde määräytyy pelkästään heidän tekemänsä yhteisymmärryksen perusteella. Listit Software Oy ei vastaa oppilaitoksen ja sijaisen tiedonvaihdosta eikä näiden antamien tiedoista tai tietojen aitoudesta.                    </p>
            <p className="termsParagraf">
                Listit Software Oy: vastaa siitä että Vikaaria palvelu on avoinna 24 tuntia vuorokaudessa seitsemänä  päivänä viikossa. Listit Software Oy:llä on oikeus ottaa Vikaaria palvelu tai sen osa tilapäisesti pois käytöstä taikka keskeyttää palvelu, jos se on tarpeen Vikaaria palvelun huollon, laitteistoasennuksen, Vikaaria palvelun liiallisen kuormituksen tai muun välttämättömän syyn vuoksi. Listit Software Oy pyrkii siihen, että keskeytys jää mahdollisimman lyhyeksi ja että siitä aiheutuu oppilaitokselle mahdollisimman vähän haittaa. Listit Software Oy ei anna takuita palvelun toiminnasta tai ominaisuuksista eikä se vastaa, että palvelu toimii käyttökatkoitta tai virheettömästi.                    </p>
            <p className="termsParagraf">
                Vikaaria palvelussa on virhe, jos se olennaisesti poikkeaa sopimuksessa määritellyistä ominaisuuksista ja tämä vaikeuttaa oleellisesti Vikaaria palvelun käyttöä.                    </p>
            <p className="termsParagraf">
                Listit Software Oy: voi kuitenkin muuttaa Vikaaria palvelun toimintaa tai ominaisuuksia ja sillä on oikeus lopettaa Vikaaria palvelun tuottaminen perustellusta syystä. Vikaaria palvelun muutokset tulevat voimaan heti kun ne on tehty.                    </p>
            <p className="termsParagraf">
                Oppilaitoksen on ilmoitettava Vikaaria palvelun virheestä välittömästi Listit Software Oy:lle. Listit Software Oy:n vastuu virheestä rajoittuu Listit Software Oy:n vastuulla olevan virheen korjaamiseen tai virheellisesti toimineen palvelun uudelleen suorittamiseen. Listit Software Oy:n vastuu ei kata sellaisen virheen korjausta, joka on aiheutunut Vikaaria-palvelun riippumattomasta tai oppilaitoksen vastuulla olevasta syystä.                    </p>
            <p className="termsParagraf">
                Listit Software Oy ei vastaa oppilaitokselle tämän sopimussuhteen ja Vikaaria palvelun käytön perusteella mahdollisesti aiheutuvista välillisistä vahingoista. Välittömistä vahingoista Listit Software Oy vastaa, jos se on toiminut tahallisesti tai törkeän huolimattomasti.
            </p>
            <p className="termsParagraf">
                Oppilaitos on tietoinen siitä, että Internet toimintaympäristönä saattaa aiheuttaa Vikaaria palvelun toimivuuteen puutteita. Tietoturvallisuus on riski Internetissä.
            </p>

            <h2 className="termsSecondHeader">2.3. Oikeus estää Vikaaria palvelun käyttö</h2>
            <p className="termsParagraf">
                Listit Software Oy:llä on oikeus, mutta ei velvollisuutta estää sopimattomaksi katsomansa sekä näiden ehtojen, lainsäädännön taikka viranomaisten määräysten vastainen sekä sellainen Vikaaria palvelun käyttö, joka vahingollista tai haitallista Vikaaria palvelun muille käyttäjille tai kolmannelle taholle taikka Listit Software Oy:lle.
            </p>
            <p className="termsParagraf">
                Oppilaitos voi käyttää Vikaaria palvelua vasta, kun on korjannut Listit Software Oy:lle havaitsemat virheet toiminnassaan ja Listit Software Oy on korjaustoimenpiteet kirjallisesti hyväksynyt.
            </p>
            <p className="termsParagraf">
                Listit Software Oy:llä on oikeus estää Vikaaria palvelun käyttö, jos:
                • Oppilaitoksen antamat tiedot ovat väärät;
                • Oppilaitos on aiheuttanut häiriöitä Vikaariae-palvelulle tai muille Vikaaria-palvelun käyttäjille;
                • Listit Software Oy arvioi oppilaitoksen sijaisesta antaman palautteen perusteella poistamisen aiheelliseksi;

            </p>
            <p className="termsParagraf">
                Listit Software Oy ilmoittaa Vikaaria palvelun käytön estämisestä oppilatokselle tai/ja Sijaiselle mahdollisimman pian havaittuaan tähän oikeuttavan perusteen.
            </p>
            <p className="termsParagraf">
                Listit Software Oy:llä on oikeus periä hinnaston mukainen maksu palvelun uudelleen avaamisesta.
            </p>

            <h2 className="termsHeader">3. Hinta</h2>
            <p className="termsParagraf">
                Palvelun hinta, lisenssit ynm. käyttöoikeudet ja ehdot löytyvät erillisestä liitteestä joka on sopimuskohtainen.
            </p>

            <h2 className="termsHeader">4. Oikeudet aineistoon</h2>
            <p className="termsParagraf">
                Listit Software Oy:n tuottaman aineiston omistus- ja kaikki muut oikeudet niin kuin ohjelmisto, kuuluvat Listit Software Oy:lle.
            </p>
            <p className="termsParagraf">
                Listit Software Oy säilyttää oppilaitoksen toimittamaa aineistoa enintään vuorokauden toimituspäivästä lukien sijaiselle, jonka jälkeen Listit Software Oy:lä on oikeus tuhota se. Listit Software Oy ei palauta aineistoa, ellei toisin ole nimenomaisesti sovittu.
            </p>

            <h2 className="termsHeader">5. Sopimuksen irtisanominen</h2>
            <p className="termsParagraf">
                Määräajaksi tehty sopimus on voimassa määräajan ja päättyy ilman irtisanomisaikaa määräajan viimeisenä päivänä. Määräaikaista sopimusta ei voi irtisanoa kesken sopimuskauden.
            </p>
            <p className="termsParagraf">
                Toistaiseksi voimassaolevan sopimuksen molemmat sopijapuolet voivat irtisanoa ilmoittamalla siitä kirjallisesti, jolloin sopimus päättyy kolmen (3) kuukauden kuluttua irtisanomisesta.
            </p>

            <h2 className="termsHeader">6. Sopimuksen purku</h2>
            <p className="termsParagraf">
                Mikäli sopijapuoli rikkoo olennaisesti tämän sopimuksen ehtoja, on toisella sopijapuolella oikeus purkaa sopimus 14 päivän kuluttua kirjallisen varoituksen annettuaan, mikäli sopijapuoli ei korjaa toimintaansa sopimuksen edellyttämäksi.
            </p>

            <h2 className="termsHeader">7.  Ylivoimainen este</h2>
            <p className="termsParagraf">
                Seuraavia seikkoja on pidettävä ylivoimaisena esteenä, mikäli ne estävät sopimuksen täyttämisen tai tekevät sen kohtuuttoman vaikeaksi: työselkkaus ja kaikki muut  sellaiset seikat, joihin sopijapuolet eivät itse voi vaikuttaa, kuten tulipalo, sota, liikekannallepano, pakko-otto, takavarikko, sähkökatko sekä häiriö tele- ja/tai internet -liikenteessä. Sopijapuoli vapautuu velvoitteistaan sekä velvollisuudestaan maksaa vahingonkorvausta ylivoimaisen esteen vaikutusajalta.
            </p>
            <p className="termsParagraf">
                Riippumatta siitä, mitä näissä sopimusehdoissa muuten on mainittu, kumpikin sopijapuoli voi purkaa sopimuksen ilmoittamalla siitä kirjallisesti, kun sopimuksen täyttäminen ylivoimaisen esteen vuoksi viivästyy yli kuusi (6) kuukautta.
            </p>
            <p className="termsParagraf">
                Ylivoimaisen esteen olemassaolosta, syystä ja sen lakkaamisesta on viipymättä ilmoitettava kirjallisesti toiselle sopijapuolelle.
            </p>

            <h2 className="termsHeader">8. Sopimuksen siirto</h2>
            <p className="termsParagraf">
                Listit Software Oy:llä on oikeus siirtää tämä sopimus Vikaaria palvelua jatkavalle yritykselle. Oppilaitoksella ei ole oikeutta siirtää tätä sopimusta ilman Listit Software Oy:n kirjallista suostumusta.
            </p>

            <h2 className="termsHeader">9. Sopimuksen muutokset</h2>
            <p className="termsParagraf">
                Näistä ehdoista  poikkeavista yksittäisen sopimuksen ehdoista tulee sopia kirjallisesti.
            </p>
            <p className="termsParagraf">
                Listit Software Oy:llä on oikeus muuttaa näitä käyttöehtoja ilmoittamalla siitä asiakkaalle/oppilaitokselle. Sopimuksen muutos astuu voimaan kahden viikon kuluttua ilmoituksen lähettämisestä sijaisen Vikaaria palvelussa ilmoittamaan tai muutoin tiedossa olevaan osoitteeseen.
            </p>

            <h2 className="termsHeader">10. Salassapito</h2>
            <p className="termsParagraf">
                Sopijapuolet sitoutuvat pitämään salassa toisiltaan saamansa aineistot ja tiedot, jotka on merkitty luottamukselliseksi tai jotka on sellaisiksi ymmärrettävä, sekä olemaan käyttämättä niitä muihin kuin sopimuksen mukaisiin tarkoi-tuksiin. Salassapitovelvollisuus ei kuitenkaan koske sellaista tietoa tai aineistoa
                <ul>
                    <li>joka on yleisesti saatavilla taikka muuten julkista;</li>
                    <li>jonka sopijapuoli on saanut kolmannelta osapuolelta ilman salassapitovelvollisuutta;</li>
                    <li>joka oli vastaanottavan sopijapuolen hallussa ilman niitä koskevaa salassapitovelvollisuutta ennen niiden saamista toiselta sopijapuolelta; tai</li>
                    <li>jonka sopijapuoli on itsenäisesti kehittänyt hyödyntämättä toiselta sopijapuolelta saamaansa aineistoa tai tietoa.</li>
                </ul>
            </p>
            <p className="termsParagraf">
                Tässä sopimuskohdassa tarkoitettu salassapitosäännös on voimassa myös tämän sopimuksen voimassaolon päät- tymisen jälkeen.
            </p>

            <h2 className="termsHeader">11. Sopimuksen voimaantulo</h2>
            <p className="termsParagraf">
                Vikaaria palvelun käyttöä koskeva sopimus tulee voimaan päivänä,  jolloin Listit Software Oy on sen hyväksynyt.
            </p>

            <h2 className="termsHeader">12. Riitojen ratkaisu</h2>
            <p className="termsParagraf">
                Tästä sopimuksesta aiheutuvat riitaisuudet ratkaistaan ensisijaisesti neuvottelemalla, mutta mikäli neuvotteluissa ei  päästä yksimielisyyteen, ratkaistaan riitaisuudet Listit Software Oy:n kotipaikkakunnan käräjäoikeudessa.
            </p>
        </div>
    )
}

export default TermsFI
