import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import logo from '../../images/logo.png';
import './legal.css'
import TermsFI from './termsFI';
import TermsSV from './termsSV';
import LanguagePicker from '../func-components/languagePicker';

const Terms = (props) => {
    const [lang, setLang] = useState("fi")

    return (
        <div className="mx-3">
            <Container className="d-flex flex-column align-items-center" fluid style={{ paddingLeft: "0", paddingRight: "0", minHeight: "100%", maxWidth: "700px" }}>
                <img src={logo} alt="Logo" className="my-3" />
                    {lang === "fi" ? (
                        <TermsFI />
                    ) : (
                        <TermsSV />
                    )}
            </Container>
        </div>
    )
}

export default Terms
