import React from "react";
import { Dropdown } from "react-bootstrap";
import { default as ExpandArrow } from "../../images/expand_arrow.svg";

const ChooseSchool = ({ language, options, onClick, reminder }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle className="vikaaria-dropdown" id="dropdown-basic">
        {reminder}
        <span className="m-auto">
          {language.fi ? "Valitse yksikkö" : "Välj enhet"}
        </span>
        <img
          src={ExpandArrow}
          style={{
            width: "1.2rem",
            filter: "brightness(0) invert(1)",
            margin: "auto",
          }}
          alt="update icon"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="sort-dropdown">
        {options.map((option) => (
          <Dropdown.Item className="dd-item" id={option} onClick={onClick}>
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default ChooseSchool;
