import React from 'react'

const TermsSV = () => {
    return (
        <div className="d-inline-block text-left">

            <h2 className="termsHeader">1. Avtalsparter </h2>
            <p className="termsParagraf">Dessa villkor gäller mellan Listit Software Ab och läroinrättning fråga</p>

            <h2 className="termsHeader">2. Parternas rättigheter och skyldigheter </h2>
            <h2 className="termsSecondHeader">2.1. Läroinrättningens och vikariens ansvar </h2>

            <p className="termsParagraf">
                Läroinrättninen är ansvarig för den information som den utger i Vikaaria tjänsten och är skyldig att uppdatera eventuella ändringar i den utan dröjsmål.
            </p>
            <p className="termsParagraf">
                Vikarien måste ge tillräcklig kontaktinformation (inklusive namn, gatuadress, telefonnummer, e-postadress, ange modersmål och status).
            </p>
            <p className="termsParagraf">
                Vikarie har inte rättighet att överföra användningsrätten till en tredje part eller att hitta vikarie i stället för ett redan godkänt vikarieat utan separat godkännande från skola.
            </p>
            <p className="termsParagraf">
                Läroinrättning och vikarie ansvarar individuellt för äktheten av informationen de anger i Vikaaria tjänsten.
            </p>
            <p className="termsParagraf">
                Läroinrättningen är ansvarig för användningen av Vikaaria tjänsten med dess användarnamn och lösenord. Skolan ansvarar för sitt eget användarnamn
            </p>
            <p className="termsParagraf">
                Vikarien åtar sig att agera i enlighet med reglerna för skola och god manér.
            </p>

            <h2 className="termsSecondHeader">2.2. Listit Software Ab:s ansvar  </h2>
            <p className="termsParagraf">
                Listit Software Ab: ger läroinrättningen rätt att använda Vikaaria tjänsten i enlighet med dessa villkor. Eftersom Vikaaria tjänsten är en marknadsplats för information om utbud och efterfrågan för vikariat, är Listit Software Ab inte på något sätt ansvarig för vikarien eller vikariens verksamhet.
            </p>
            <p className="termsParagraf">
                Koska Vikaaria palvelu on sijaisuuksia koskevan kysyntä- ja tarjontatiedon markkinapaikka, ei Listit Software Oy: millään lailla vastaa sijaisista tai heidän toiminnastaan. Sijaisen ja oppilaitoksen suhde määräytyy pelkästään heidän tekemänsä yhteisymmärryksen perusteella. Listit Software Oy ei vastaa oppilaitoksen ja sijaisen tiedonvaihdosta eikä näiden antamien tiedoista tai tietojen aitoudesta.
            </p>
            <p className="termsParagraf">
                Förhållandet mellan vikarie och läroinrättning bestäms enbart av det avtal som görs. Listit Software Ab strävar efter att Vikaaria tjänsten skall vara öppen 24 timmar i dygnet, sju dagar i veckan.
            </p>
            <p className="termsParagraf">
                Listit Software Ab har rätt att tillfälligt inaktivera Vikaaria tjänsten utan förvarning eller stänga av tjänsten om så ser nödvändigt på grund av underhåll, uppdatering, hårdvaruinstallation eller överbelastning av tjänsten eller andra nödvändiga skäl.
            </p>
            <p className="termsParagraf">
                Listit Software Ab strävar efter att hålla avbrott så kort som möjligt och att orsaka minsta möjliga olägenhet för läroinrättning. Listit Software Ab garanterar inte funktionen eller funktionerna i tjänsten och ansvarar inte för den oavbrutna eller felfria driften av tjänsten.
            </p>
            <p className="termsParagraf">
                Det finns fel i Vikaaria tjänsten om den skiljer sig väsentligt från de funktioner som anges i kontraktet och detta väsentligt hindrar användningen av Vikaaria tjänsten. Listit Software Ab kan dock ändra funktionen eller funktionerna i Vikaaria  tjänsten och har rätt att sluta tillhandahålla Vikaaria tjänsten av en motiverad anledning.
            </p>
            <p className="termsParagraf">
                Ändringar av Vikaaria tjänsten träder i kraft så snart de görs. Läroinrättningen måste omedelbart meddela Listit Software Ab om det uppstår fel i tjänsten. Listit Software Ab: s ansvar för felet är begränsat till att rätta till det fel som Listit Software Ab ansvarar för eller för att åter utföra tjänsten som misslyckades.
            </p>
            <p className="termsParagraf">
                Listit Software Ab: s ansvar täcker inte rättelse av ett fel orsakat av en orsak som är oberoende av Vikaaria tjänsten eller som läroinrättningen ansvarar för. Listit Software Ab ansvarar inte för några indirekta skador som kan orsakas av läroinrättning på grundval av detta avtalsförhållande och användningen av Vikaaria tjänsten.
            </p>

            <h2 className="termsSecondHeader">2.3. Rätten att blockera </h2>
            <p className="termsParagraf">
                Listit Software Ab har rätten att förhindra användningen av Vikaaria  tjänsten, men inte skyldighet, att förhindra användning av Vikaaria tjänsten om den anser vara olämplig och strider mot dessa villkor, lagstiftning eller officiella föreskrifter, eller som är skadlig eller skadlig för andra användare av Vikaaria tjänsten eller tredje part eller Listit Software Ab. Läroinrättningen kan endast använda Vikaaria tjänsten efter att den har korrigerat de fel som upptäckts av Listit Software Ab i sin verksamhet och Listit Software Ab har godkänt korrigerande åtgärder skriftligen.
            </p>
            {/* <p className="termsParagraf">
                Oppilaitos voi käyttää Vikaaria palvelua vasta, kun on korjannut Listit Software Oy:lle havaitsemat virheet toiminnassaan ja Listit Software Oy on korjaustoimenpiteet kirjallisesti hyväksynyt.
            </p> */}
            <p className="termsParagraf">
                Listit Software Ab har rätt att blockera användningen av Vikaaria -tjänsten om:
                • Informationen från institutionen är felaktig;
                • Läroinrättningen har orsakat störningar i Vikaaria tjänsten eller för andra användare av tjänsten;
                • Listit Software Ab anser att borttagningen är lämplig på grundval av feedback från vikarie.
            </p>
            <p className="termsParagraf">
                Listit Software Ab meddelar skolan och / eller vikarien om användningen av Vikaaria tjänsten så snart som möjligt efter att ha märkt en motivering för detta. Listit Software Ab har rätt att ta ut en avgift i enlighet med kontraktet för att öppna tjänsten tillbaka.
            </p>
            {/*  <p className="termsParagraf">
                Listit Software Oy:llä on oikeus periä hinnaston mukainen maksu palvelun uudelleen avaamisesta.
            </p> */}

            <h2 className="termsHeader">3. Pris</h2>
            <p className="termsParagraf">
                Pris på tjänsten, licenser, rättigheter etc. villkor finns i en separat bilaga som är kontraktsspecifik.
            </p>

            <h2 className="termsHeader">4. Rättigheter till materialet</h2>
            <p className="termsParagraf">
                Rättigheter till materialet, ägandet och alla andra rättigheter för materialet som produceras av Listit Software Ab, liksom programvaran, tillhör Listit Software Ab. Listit Software Ab behåller materialet som tillhandahålls av skola i högst en dag från leveransdatum till, varefter Listit Software Ab har rätt att förstöra det. Listit Software Ab returnerar inte materialet om inte annat uttryckligen har överenskommits.
            </p>
            {/* <p className="termsParagraf">
                Listit Software Oy säilyttää oppilaitoksen toimittamaa aineistoa enintään vuorokauden toimituspäivästä lukien sijaiselle, jonka jälkeen Listit Software Oy:lä on oikeus tuhota se. Listit Software Oy ei palauta aineistoa, ellei toisin ole nimenomaisesti sovittu.
            </p> */}

            <h2 className="termsHeader">5. Uppsägning av avtalet under avtalsperioden</h2>
            <p className="termsParagraf">
                Ett tidsavtal gäller för en specifik tid och löper ut automatiskt den sista dagen av avtalstiden. Ett tidsavtal kan inte sägas upp under avtalsperioden. Båda parterna i detta avtal kan säga upp avtalet skriftligen, i vilket fall avtalet upphör tre (3) månader efter uppsägningen.
            </p>
            {/* <p className="termsParagraf">
                Toistaiseksi voimassaolevan sopimuksen molemmat sopijapuolet voivat irtisanoa ilmoittamalla siitä kirjallisesti, jolloin sopimus päättyy kolmen (3) kuukauden kuluttua irtisanomisesta.
            </p> */}

            <h2 className="termsHeader">6. Uppsägning av avtalet </h2>
            <p className="termsParagraf">
                Om någondera part bryter mot villkoren i detta avtal har den andra parten rätt att säga upp avtalet 14 dagar efter skriftlig begäran, såvida inte parten korrigerar sina handlingar i enlighet med avtalet.
            </p>

            <h2 className="termsHeader">7.  Force majeure </h2>
            <p className="termsParagraf">
                Följande ska betraktas som force majeure om de förhindrar eller på ett otillbörligt sätt hindrar kontraktets genomförande: industriella åtgärder och alla andra ärenden som parterna inte har kontroll över, såsom brand, krig, mobilisering, utmätning, beslag, strömavbrott och störningar av telekommunikation och / eller internet trafik. Den avtalsslutande parten ska befrias från sina skyldigheter och från sin skyldighet att betala skadestånd för force majeure. Trots något motsatt i dessa villkor kan vardera parten säga upp detta avtal genom att skriftligen meddela fördröjning i genomförandet av avtalet på grund av force majeure i mer än sex (6) månader. Förekomst, orsak och upphörande av force majeure ska omedelbart meddelas skriftligen till den andra parten.
            </p>
            {/* <p className="termsParagraf">
                Riippumatta siitä, mitä näissä sopimusehdoissa muuten on mainittu, kumpikin sopijapuoli voi purkaa sopimuksen ilmoittamalla siitä kirjallisesti, kun sopimuksen täyttäminen ylivoimaisen esteen vuoksi viivästyy yli kuusi (6) kuukautta.
            </p>
            <p className="termsParagraf">
                Ylivoimaisen esteen olemassaolosta, syystä ja sen lakkaamisesta on viipymättä ilmoitettava kirjallisesti toiselle sopijapuolelle.
            </p> */}

            <h2 className="termsHeader">8. Överlåtelse av kontraktet </h2>
            <p className="termsParagraf">
                Listit Software Ab har rätt att överföra detta avtal till ett företag som fortsätter Vikaaria tjänsten.
            </p>

            <h2 className="termsHeader">9. Ändringar av avtalet </h2>
            <p className="termsParagraf">
                Villkoren i ett enskilt kontrakt som avviker från dessa villkor måste avtalas skriftligen.  Listit Software Ab har rätt att ändra dessa användarvillkor genom att meddela kunden / institutionen.  Ändringen av avtalet träder i kraft två veckor efter att anmälan har skickats till den adress som ställföreträdaren i Vikaaria tjänsten uppger eller på annat sätt är känd.
            </p>
            <p className="termsParagraf">
                Listit Software Oy:llä on oikeus muuttaa näitä käyttöehtoja ilmoittamalla siitä asiakkaalle/oppilaitokselle. Sopimuksen muutos astuu voimaan kahden viikon kuluttua ilmoituksen lähettämisestä sijaisen Vikaaria palvelussa ilmoittamaan tai muutoin tiedossa olevaan osoitteeseen.
            </p>

            <h2 className="termsHeader">10. Sekretess</h2>
            <p className="termsParagraf">
                Parterna förbinder sig att hålla det material och den information som mottagits från varandra konfidentiella, som är märkta som konfidentiella eller som måste förstås som sådana, och inte använda dem för andra ändamål än de som anges i avtalet.
            </p>
            <p className="termsParagraf">
                Tystnadsplikten gäller dock inte för sådan information eller sådant material
                <ul>
                    <li>som är offentligt tillgänglig eller på annat sätt offentlig; </li>
                    <li>erhållits av en avtalspart från en tredje part utan tystnadsplikt;</li>
                    <li>som var i den mottagande avtalspartens besittning utan tystnadsplikt innan han mottog dem från den andra avtalsparten; eller </li>
                    <li>Utvecklas oberoende av en part utan att använda material eller information från den andra parten. Den icke avslöjande bestämmelse som avses i denna punkt ska överleva när detta avtal sägs upp. </li>
                </ul>
            </p>

            <h2 className="termsHeader">11. Avtalets ikraftträdande </h2>
            <p className="termsParagraf">
                Avtalet om användning av Vikaaria tjänsten träder i kraft den dag då Listit Software Ab har godkänt det.
            </p>

            <h2 className="termsHeader">12. Tvistlösning</h2>
            <p className="termsParagraf">
                Tvister som härrör från detta avtal kommer att lösas främst genom förhandlingar, men om det inte uppnås någon överenskommelse i förhandlingarna kommer tvisterna att lösas i Listit Software Ab: s hemvist.
            </p>
        </div>
    )
}

export default TermsSV
