import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import CustomNavbar from "../navigation/customNavbar";
import {hasEditedProfile, logoutTeacher} from "../../actions/authActions";
import PropTypes from "prop-types";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import SharedProfile from "../profiles/sharedProfile";
import MobileLayout from "../mobile/mobileLayout";
import {default as MoreIcon} from "../tempjob/icons/dot_menu.svg";
import "./userSettings.css";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Notifications from "./settings-pages/notifications";
import Account from "./settings-pages/account";
import Security from "./settings-pages/security";
import sv from "../../texts/sv.json";
import fi from "../../texts/fi.json";
import FAQ from "../func-components/faq";
import UserFaqData from "../../data/userFaqData";
import TeacherFaqData from "../../data/teacherFaqData";
import Sort from "../func-components/sort";
import Backbar from "../mobile/backbar";
import Support from "./settings-pages/support";
import EditProfileModal from "../func-components/editProfileModal";
import DefaultDescriptionCard from "../cards/DefaultDescriptionCard";

const UserSettings = (props) => {
    const {} = props;
    const [sidebar, setSidebar] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [render, setRender] = useState(false);
    const [showQ, setShowQ] = useState("");
    const [lessPad, setLessPad] = useState(false);
    const [language, setLanguage] = useState({});
    const [comp, setComp] = useState(null);
    const [page, setPage] = useState(null);
    const [hideNav, setHideNav] = useState(false);
    const [hasEdited, setHasEdited] = useState(false);

    const components = [
        {id: 1, component: Notifications},
        {id: 2, component: Account},
        {id: 3, component: SharedProfile},
        {id: 4, component: Security},
        /* { id: 5, component: Support } */
    ];

    const getLang = (user) => {
        if (user.siteLang === 2) {
            setLanguage(sv);
        } else if (user.siteLang === 1) {
            setLanguage(fi);
        } else {
            setLanguage(fi);
        }
    };

    useEffect(() => {
        if (render && mobile) {
            setHideNav(true);
        } else {
            setHideNav(false);
        }
    }, [render, mobile]);

    useEffect(() => {
        getLang(props.auth.teacher);
        setLessPad(false);
        if (props?.location?.state?.compState) {
            setRender(true);
            setComp(props?.location?.state?.compState);
            if (language.fi || language.sv) {
                setPage(
                    language.fi
                        ? props?.location?.state?.pageState?.fi
                        : props?.location?.state?.pageState?.sv
                );
            } else {
                setPage(
                    props.auth.teacher.siteLang === 1
                        ? props?.location?.state?.pageState?.fi
                        : props?.location?.state?.pageState?.sv
                );
            }
        }
    }, [props?.location?.state?.compState]);

    useEffect(() => {
        let isMounted = true;
        if (window.innerWidth <= 600) {
            setMobile(true);
            setSidebar(false);
        }
        if (props.auth.teacher.typeOfUser === "user") {
            let promise = props.hasEditedProfile({email: props.auth.teacher.email});
            promise.then((data) => {
                let tempHasEdited = data.hasEdited;
                if (tempHasEdited === "undefined" || tempHasEdited === null) {
                    tempHasEdited = false;
                }
                isMounted && setHasEdited(tempHasEdited);
                if (!tempHasEdited && isMounted) {
                    setIsOpen(true);
                }
            });
        }
        return () => (isMounted = false);
    }, []);

    const faqToggle = (index) => {
        if (showQ === index) {
            setShowQ("");
        } else {
            setShowQ(index);
        }
    };
    const handleCloseChange = () => {
        setIsOpen(false);
    };

    return (
        <div className="temp-body">
            {!hideNav && (
                <CustomNavbar
                    userType={props.auth.teacher.typeOfUser}
                    sidebar={sidebar}
                    showSidebar={() => setSidebar((prevSidebar) => !prevSidebar)}
                    page={mobile ? language.settings : page}
                    user={props.auth.teacher}
                    logoutTeacher={() => props.logoutTeacher()}
                    isAuth={props.auth.isAuthenticated}
                />
            )}
            {/* mobile layout below */}
            {/* <FontAwesomeIcon size="lg" color="#707070" icon={faChevronRight} /> */}
            {mobile && (
                <div className={sidebar ? "main push-right-user" : "main"}>
                    {!hasEdited && (
                        <EditProfileModal
                            isOpen={isOpen}
                            handleCloseChange={handleCloseChange}
                            language={language}
                        />
                    )}
                    <Container
                        fluid
                        style={{
                            paddingLeft: "0",
                            paddingRight: "0",
                            minHeight: "100vh",
                            marginBottom: "6rem",
                        }}
                    >
                        <MobileLayout
                            text={language.more}
                            avatar={true}
                            icon={MoreIcon}
                            user={props.auth.teacher}
                            renderCard={false}
                            lessPadding={true}
                        />
                        {!render ? (
                            <Card
                                className="mb-4 align-middle"
                                style={{
                                    marginLeft: "1em",
                                    marginRight: "1em",
                                    marginTop: "1em",
                                    borderRadius: "20px",
                                    border: "none",
                                    boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                                }}
                            >
                                <Card.Body>
                                    {/* <Card.Title className="text-muted smaller">Allmänt</Card.Title> */}

                                    <Card.Text
                                        onClick={() => {
                                            setRender(true);
                                            setComp(1);
                                        }}
                                        className="list-text d-flex"
                                    >
                                        {language.notifications}{" "}
                                        <FontAwesomeIcon
                                            color="#707070"
                                            className="ml-auto mt-1"
                                            icon={faChevronRight}
                                            size="xs"
                                        />
                                    </Card.Text>

                                    <hr className="list-separator"></hr>

                                    {/* <Card.Text onClick={() => {
                                    setRender(true)
                                    setComp(2)
                                }} className="list-text d-flex">{language.account} <FontAwesomeIcon color="#707070" className="ml-auto mt-1" icon={faChevronRight} size="xs" /></Card.Text> */}

                                    {/* <hr className="list-separator"></hr> */}

                                    <Card.Text
                                        onClick={() => {
                                            setRender(true);
                                            setComp(3);
                                        }}
                                        className="list-text d-flex"
                                    >
                                        {language.profile}{" "}
                                        <FontAwesomeIcon
                                            color="#707070"
                                            className="ml-auto mt-1"
                                            icon={faChevronRight}
                                            size="xs"
                                        />
                                    </Card.Text>

                                    <hr className="list-separator"></hr>

                                    <Card.Text
                                        onClick={() => {
                                            setRender(true);
                                            setComp(5);
                                        }}
                                        className="list-text-dt d-flex"
                                    >
                                        FAQ{" "}
                                        <FontAwesomeIcon
                                            color="#707070"
                                            className="ml-auto mt-1"
                                            icon={faChevronRight}
                                            size="xs"
                                        />
                                    </Card.Text>

                                    <hr className="list-separator"></hr>

                                    <Card.Text
                                        onClick={() => {
                                            setRender(true);
                                            setComp(6);
                                        }}
                                        className="list-text d-flex"
                                    >
                                        {language.support}{" "}
                                        <FontAwesomeIcon
                                            color="#707070"
                                            className="ml-auto mt-1"
                                            icon={faChevronRight}
                                            size="xs"
                                        />
                                    </Card.Text>

                                    <hr className="list-separator"></hr>

                                    <Card.Text
                                        onClick={() => {
                                            setRender(true);
                                            setComp(4);
                                        }}
                                        className="list-text d-flex"
                                    >
                                        {language.security}{" "}
                                        <FontAwesomeIcon
                                            color="#707070"
                                            className="ml-auto mt-1"
                                            icon={faChevronRight}
                                            size="xs"
                                        />
                                    </Card.Text>

                                    <hr className="list-separator"></hr>

                                    <Button
                                        variant="custom"
                                        block
                                        size="lg"
                                        onClick={props.logoutTeacher}
                                    >
                                        {language.logout}
                                    </Button>
                                    <div id="version-text" className="version version-middle">
                                        v1.5.6
                                    </div>
                                </Card.Body>
                            </Card>
                        ) : (
                            (comp === 1 && (
                                <Notifications
                                    desktop={!mobile}
                                    setrender={setRender}
                                    language={language}
                                />
                            )) ||
                            /* || (comp === 2 &&
                                                                                                                                                          <Account setrender={setRender} language={language} />) */
                            (comp === 3 && (
                                <SharedProfile
                                    mobile={true}
                                    setrender={setRender}
                                    language={language}
                                />
                            )) ||
                            (comp === 4 && (
                                <Security
                                    mobile={true}
                                    setrender={setRender}
                                    language={language}
                                    desktop={!mobile}
                                />
                            )) ||
                            (comp === 5 && (
                                <Card
                                    className=" mx-3 mt-2"
                                    style={{
                                        borderRadius: "20px",
                                        border: "none",
                                        boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Backbar
                                        padding={true}
                                        sticky={mobile}
                                        desktop={!mobile}
                                        setrender={setRender}
                                        title="FAQ"
                                    />
                                    <Card.Body style={{paddingTop: "0"}}>
                                        {props.auth.teacher.typeOfUser === "user"
                                            ? UserFaqData.map((faq, index) => (
                                                <FAQ
                                                    faq={faq}
                                                    index={index}
                                                    toggleFaq={faqToggle}
                                                    showQ={showQ}
                                                    lang={language}
                                                />
                                            ))
                                            : TeacherFaqData.map((faq, index) => (
                                                <FAQ
                                                    faq={faq}
                                                    index={index}
                                                    toggleFaq={faqToggle}
                                                    showQ={showQ}
                                                    lang={language}
                                                />
                                            ))}
                                    </Card.Body>
                                    <div id="version-text" className="version version-middle">
                                        v1.5.6
                                    </div>
                                </Card>
                            )) ||
                            (comp === 6 && (
                                <Support
                                    mobile={true}
                                    setrender={setRender}
                                    language={language}
                                />
                            ))
                        )}
                    </Container>
                </div>
            )}

            {/* desktop layout */}
            {!mobile && (
                <>
                    <Sort
                        header={language.settings}
                        userType={props.auth.teacher.typeOfUser}
                        marginLeft={sidebar ? "215px" : "16px"}
                        school={props.auth.teacher.school}
                    />
                    <hr
                        style={{marginTop: "60px", position: "fixed", zIndex: "9"}}
                        className="custom-hr"
                    ></hr>
                    <div className={sidebar ? "main push-right" : "main settings-margin"}>
                        {/* <Container fluid>
                        {faqData.map((faq, index) => (
                            <FAQ faq={faq} index={index} toggleFaq={faqToggle} showQ={showQ} />
                        ))}
                    </Container> */}
                        <Container
                            fluid
                            style={{
                                minHeight: "100vh",
                            }}
                        >
                            <Row
                                className="f-col"
                                style={{
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                <Col
                                    style={{
                                        margin: "0",
                                        padding: "0",
                                    }}
                                >
                                    {!render ? (
                                        <Card
                                            className="mb-4 mt-2"
                                            style={{
                                                borderRadius: "14px",
                                                border: "none",
                                                boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                                            }}
                                        >
                                            <Card.Body>
                                                {/* <Card.Title className="text-muted smaller">Allmänt</Card.Title> */}

                                                <Card.Text
                                                    onClick={() => {
                                                        setRender(true);
                                                        setComp(1);
                                                    }}
                                                    className="list-text-dt d-flex"
                                                >
                                                    {language.notifications}{" "}
                                                    <FontAwesomeIcon
                                                        color="#707070"
                                                        className="ml-auto mt-1"
                                                        icon={faChevronRight}
                                                        size="xs"
                                                    />
                                                </Card.Text>

                                                <hr className="list-separator"></hr>

                                                {/* <Card.Text onClick={() => {
                                                setRender(true)
                                                setComp(2)
                                            }} className="list-text-dt d-flex">{language.account} <FontAwesomeIcon color="#707070" className="ml-auto mt-1" icon={faChevronRight} size="xs" /></Card.Text> */}

                                                {/* <hr className="list-separator"></hr> */}

                                                <Card.Text
                                                    onClick={() => {
                                                        setRender(true);
                                                        setComp(4);
                                                    }}
                                                    className="list-text-dt d-flex"
                                                >
                                                    {language.security}{" "}
                                                    <FontAwesomeIcon
                                                        color="#707070"
                                                        className="ml-auto mt-1"
                                                        icon={faChevronRight}
                                                        size="xs"
                                                    />
                                                </Card.Text>

                                                <hr className="list-separator"></hr>

                                                <Card.Text
                                                    onClick={() => {
                                                        setRender(true);
                                                        setComp(6);
                                                    }}
                                                    className="list-text-dt d-flex"
                                                >
                                                    {language.support}{" "}
                                                    <FontAwesomeIcon
                                                        color="#707070"
                                                        className="ml-auto mt-1"
                                                        icon={faChevronRight}
                                                        size="xs"
                                                    />
                                                </Card.Text>

                                                <hr className="list-separator"></hr>

                                                <Card.Text
                                                    onClick={() => {
                                                        setRender(true);
                                                        setComp(5);
                                                    }}
                                                    className="list-text-dt d-flex"
                                                >
                                                    FAQ{" "}
                                                    <FontAwesomeIcon
                                                        color="#707070"
                                                        className="ml-auto mt-1"
                                                        icon={faChevronRight}
                                                        size="xs"
                                                    />
                                                </Card.Text>

                                                <Button
                                                    variant="custom"
                                                    block
                                                    size="lg"
                                                    onClick={props.logoutTeacher}
                                                >
                                                    {language.logout}
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    ) : (
                                        (comp === 1 && (
                                            <Notifications
                                                setrender={setRender}
                                                language={language}
                                                desktop={!mobile}
                                            />
                                        )) ||
                                        (comp === 7 &&
                                            props.auth.teacher.typeOfUser === "teacher" && (
                                                <DefaultDescriptionCard
                                                    user={props.auth.teacher}
                                                    language={language}
                                                />
                                            )) ||
                                        (comp === 3 && (
                                            <SharedProfile
                                                mobile={true}
                                                setrender={setRender}
                                                language={language}
                                                desktop={!mobile}
                                            />
                                        )) ||
                                        (comp === 4 && (
                                            <Security
                                                mobile={true}
                                                setrender={setRender}
                                                language={language}
                                                desktop={!mobile}
                                            />
                                        )) ||
                                        (comp === 5 && (
                                            <Card
                                                className="mb-4"
                                                style={{
                                                    marginTop: "20px",
                                                    borderRadius: "20px",
                                                    border: "none",
                                                    boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                                                }}
                                            >
                                                <Backbar
                                                    padding={true}
                                                    sticky={mobile}
                                                    desktop={!mobile}
                                                    noBack={!mobile}
                                                    setrender={setRender}
                                                    title="FAQ"
                                                />
                                                <Card.Body>
                                                    {props.auth.teacher.typeOfUser === "user"
                                                        ? UserFaqData.map((faq, index) => (
                                                            <FAQ
                                                                faq={faq}
                                                                index={index}
                                                                toggleFaq={faqToggle}
                                                                showQ={showQ}
                                                                lang={language}
                                                            />
                                                        ))
                                                        : TeacherFaqData.map((faq, index) => (
                                                            <FAQ
                                                                faq={faq}
                                                                index={index}
                                                                toggleFaq={faqToggle}
                                                                showQ={showQ}
                                                                lang={language}
                                                            />
                                                        ))}
                                                </Card.Body>
                                            </Card>
                                        )) ||
                                        (comp === 6 && (
                                            <Support
                                                mobile={false}
                                                setrender={setRender}
                                                language={language}
                                            />
                                        ))
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
            )}
        </div>
    );
};
UserSettings.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    logoutTeacher: PropTypes.func.isRequired,
    hasEditedProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {logoutTeacher, hasEditedProfile})(
    UserSettings
);
